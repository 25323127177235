<template>
  <div class="row" style="margin-bottom: 20px;">
    <div class="col-12">
      <h2>{{ activeLanguage.title }}</h2>
    </div>
    <div class="col-12">
      <div class="row">
        <loading-statistic :active="isLoading"
                           :canCancel="true"></loading-statistic>
      </div>
      <table class="table">
        <thead>
        <tr>
          <th width="200px">{{ activeLanguage.segments }}</th>
          <th>{{ activeLanguage.orders }}</th>
          <th>{{ activeLanguage.totalRev }}</th>
          <th>{{ activeLanguage.credited }}</th>
          <th>{{ activeLanguage.creditedProc }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="segment in segments">
          <td>{{ segment.order_amount_segment }}</td>
          <td>{{ parseInt(segment.number_of_orders).toLocaleString('de-DE') }}</td>
          <td>€ {{ formattedAmount(segment.total_order_amount) }}</td>
          <td>€ {{ formattedAmount(segment.credited_amount) }}</td>
          <td>{{ (segment['%credited_amount'] * 100) | numberFormat(2) }} %</td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td>
            {{ activeLanguage.total }}
          </td>
          <td>
            {{ totalOrders.toLocaleString('de-DE') }}
          </td>
          <td>
            € {{ formattedAmount(totalAmount) }}
          </td>
          <td>
            € {{ formattedAmount(totalCredited) }}
          </td>
          <td>
            {{ totalCreditedProc | numberFormat(2) }} %
          </td>
        </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import Loading from './Loading'

export default {
  name: "OrderDetailsTable",
  components: {
    'loading-statistic': Loading
  },
  props: {
    activeLanguage: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  computed: {
    formattedAmount() {
      return (amount) => Helper.formatAmount(amount);
    },
  },
  data() {
    return {
      segments: [],
      totalOrders: 0,
      totalAmount: 0,
      totalCredited: 0,
      totalCreditedProc: 0,
      isLoading: false
    }
  },
  methods: {
    getSegments({filter, isEmployee}) {
      let data = null
      if (isEmployee) {
        data = {
          username: filter.client.username
        }
      }
      data = {
        ...data,
        ...filter
      }

      delete data.client
      delete data.isEmployee

      newbillinkAxios
      .post('/statistics/order/overview_segments', data)
      .then(({data}) => {
        this.clear()

        this.segments = data.order_overview_per_segments

        let percentage = 0

        this.segments.forEach(
            segment => {
              this.totalOrders += parseFloat(segment.number_of_orders)
              this.totalAmount += parseFloat(segment.total_order_amount)
              this.totalCredited = segment.credited_amount ? this.totalCredited + parseFloat(segment.credited_amount) : this.totalCredited

              percentage = parseFloat(segment['%credited_amount']) ? percentage + parseFloat(segment['%credited_amount']) : percentage
            }
        )

        this.totalCreditedProc = (this.totalCredited / this.totalAmount) * 100
        this.isLoading = false

      })
      .catch(err => {
        console.error(err)
        this.isLoading = true
      })
    },
    clear() {
      this.totalOrders = 0
      this.totalAmount = 0
      this.totalCredited = 0
      this.totalCreditedProc = 0
    }
  }
}
</script>

<style scoped>
tfoot td {
  /* color: #fff; */
  text-transform: uppercase;
  /* font-size: 0.8795rem; */
  font-weight: 600 !important;
  /* font-family: 'Titillium Web', Arial, 'Helvetica Neue', Helvetica, sans-serif !important; */
  padding: .75rem !important;
  text-align: left !important;
}

tfoot {
  background: #eeeeee;
}
</style>