<template>
    <div class="row">
        <div class="col-12">
        <div class="filter-bar-container">
            <div class="filter-bar">
                <button type="button" class="btn btn-secondary">
                    <i class="filter-icon fa fa-filter fa-lg" aria-hidden="true"></i>
                    {{ activeLanguage.filterBtn }}
                </button>
            </div>
        <div class="show collapse" id="collapseExample">
            <div class="filter-content">
                <form @submit.prevent="onFilterSubmit">
                    <div class="form-row">
                        <div class="form-group col-lg-7">
                            <div id="event_period">
                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label class="sr-only" for="dateFrom">{{ activeLanguage.from }}</label>
                                        <div class="input-group">
                                            <Datepicker
                                                v-model="form.dateFrom"
                                                :calendar-button="true"
                                                calendar-button-icon="fa fa-calendar"
                                                input-class="form-control actual_range"
                                                :placeholder="activeLanguage.from"
                                                :highlighted="highlighted"
                                                format="dd-MM-yyyy"
                                                :language="langActive"
                                                :clear-button="true"
                                            />
                                        </div>

                                    </div>
                                    <div class="form-group col-sm-6 col-md-4" style="margin-bottom: 20px;">
                                        <label class="sr-only" for="dateTo">{{ activeLanguage.to }}</label>
                                        <div class="input-group mg-l-50">
                                            <Datepicker
                                                v-model="form.dateTo"
                                                :calendar-button="true"
                                                calendar-button-icon="fa fa-calendar"
                                                input-class="form-control actual_range"
                                                :placeholder="activeLanguage.to"
                                                format="dd-MM-yyyy"
                                                :language="langActive"
                                                :clear-button="true"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-12" v-if="manageOrdersPermission">
                                        <div class="form-row">
                                            <div class="form-group col-md-4">
                                                <label for="input6" class="col-form-label">{{ activeLanguage.client }}</label>
                                                <v-autocomplete
                                                    :min-len="2"
                                                    v-model="client"
                                                    :items="clients"
                                                    :get-label="getLabel"
                                                    :component-item='itemTemplate'
                                                    @update-items="updateClientList"
                                                    @item-selected="clientSelected"
                                                >
                                                </v-autocomplete>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-12 filter-btn-pos">
                                        <button type="submit" class="btn mr-1">{{ activeLanguage.applyBtn }}</button>
                                        <button type="button" @click="clearFilter" class="btn btn-secondary">{{ activeLanguage.clearBtn }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    </div>
    </div>
</template>

<script>
    import moment from 'moment'

	import ClientItemTemplate from '../common/reusable/ClientItemTemplate.vue'
    import Datepicker from 'vuejs-datepicker';
    import { en, nl } from 'vuejs-datepicker/dist/locale'
    import { mapGetters } from 'vuex'
    import  Helpers from '../../helpers/modules/scripts'

	export default {
		name: "StatisticsFilter",
        props: {
	        activeLanguage: {
	        	type: Object,
                default: function () {
                    return {}
                }
            },
	        isSuperUser: {
	        	type: Boolean,
                default: false
            },
	        manageOrdersPermission: {
	        	type: Boolean,
                default: false
            },
	        computedConnectedUsers: { },
        },
        data() {
			return {
				itemTemplate: ClientItemTemplate,
				clients: [],
                client: null,
                form: {},
                nl: nl,
                en: en,
        highlighted: {
          dates: [
            new Date()
          ]
        }
            }
        },
        mounted() {
            this.nl.months = Helpers.uppercaseArr(this.nl.months)
            this.nl.monthsAbbr = Helpers.uppercaseArr(this.nl.monthsAbbr)
            this.nl.days = Helpers.uppercaseArr(this.nl.days)
            let subtractedDate = moment().subtract(2, "months")
            let currentDay = moment()
            this.form.dateFrom = subtractedDate._d
            this.form.dateTo = currentDay._d
            this.$forceUpdate()
        },
        computed: {
            ...mapGetters({
                getActiveLanguageName: 'getActiveLanguageName'
            }),
            langActive () {
                return this.getActiveLanguageName === 'english' ? this.en : this.nl
            }
        },
        methods: {
	        updateClientList(val) {
	        	if (this.isSuperUser) {
			        this.$store.dispatch('startLoader')
			        this.clients = Helper.superUserFilter(val, this.computedConnectedUsers)
			        this.$store.dispatch('endLoader')
                } else {
			        billinkAxios
				        .post('app/clients/list', { username: val })
				        .then(({data}) => this.clients = data.clients)
				        .catch(err => console.error(err))
		        }
	        },
	        clientSelected(val) {
	        	this.client = val
		        this.form.client = val
	        },
	        getLabel (item) {
		        return !!item ? item.username : ''
	        },
	        onFilterSubmit() {
		        if (this.form.dateFrom) { this.form.dateFrom = moment(this.form.dateFrom).format('YYYY-MM-DD') }
		        if (this.form.dateTo) { this.form.dateTo = moment(this.form.dateTo).format('YYYY-MM-DD') }
		        if (!!this.client) { this.form.client = this.client }

		        if (!!this.form.client || !this.manageOrdersPermission) {
			        this.$emit('filterUpdated', this.form)
                }
	        },
	        clearFilter() {
                this.clients = []
		        this.form = {}
		        this.client = null
		        this.$emit('filterUpdated', {})
            }
        },
        components: {
	        ClientItemTemplate,
	        Datepicker
        }
	}
</script>

<style>
    .vdp-datepicker {
        z-index: 1000;
    }
    .mg-l-50 {
       margin-left: 50px; 
    }
</style>