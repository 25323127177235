<template>
  <div class="row" style="margin-bottom: 20px;">
    <div class="col-12">
      <h2>{{ activeLanguage.title }}</h2>
    </div>
    <div class="col-12">
      <div class="row">
        <loading-statistic :active="isLoading"
                           :canCancel="true"></loading-statistic>
      </div>
      <table class="table">
        <thead>
        <tr>
          <th></th>
          <th># {{ activeLanguage.orders }}</th>
          <th>% {{ activeLanguage.orders }}</th>
          <th>{{ activeLanguage.totalRev }}</th>
          <th>{{ activeLanguage.avg }}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Prive</td>
          <td>{{ privateOrders.numOfOrders.toLocaleString('de-DE') }}</td>
          <td>{{ privateOrders.percentage | numberFormat(2) }}%</td>
          <td>€ {{ formattedAmount(privateOrders.totalAmount) }}</td>
          <td>€ {{ formattedAmount(privateOrders.avgAmount) }}</td>
        </tr>
        <tr>
          <td>Zakelijk</td>
          <td>{{ companyOrders.numOfOrders.toLocaleString('de-DE') }}</td>
          <td>{{ companyOrders.percentage | numberFormat(2) }}%</td>
          <td>€ {{ formattedAmount(companyOrders.totalAmount) }}</td>
          <td>€ {{ formattedAmount(companyOrders.avgAmount) }}</td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td>
            {{ activeLanguage.total }}
          </td>
          <td>
            {{ totalOrdersNum.toLocaleString('de-DE') }}
          </td>
          <td>
            100%
          </td>
          <td>
            € {{ formattedAmount(totalSum) }}
          </td>
          <td>
            € {{ formattedAmount(totalAvg) }}
          </td>
        </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import Loading from './Loading'

export default {
  name: "OrderOverviewTable",
  components: {
    'loading-statistic': Loading
  },
  props: {
    activeLanguage: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  computed: {
    formattedAmount() {
      return (amount) => Helper.formatAmount(amount);
    },
  },
  data() {
    return {
      privateOrders: {
        numOfOrders: 0,
        totalAmount: 0,
        avgAmount: 0,
        percentage: 0
      },
      companyOrders: {
        numOfOrders: 0,
        totalAmount: 0,
        avgAmount: 0,
        percentage: 0
      },
      totalOrdersNum: null,
      totalSum: null,
      totalAvg: null,
      isLoading: false
    }
  },
  methods: {
    getOrderOverview({filter, isEmployee}) {
      let data = null
      if (isEmployee) {
        data = {
          username: filter.client.username
        }
      }
      data = {
        ...data,
        ...filter
      }

      delete data.client
      delete data.isEmployee

      newbillinkAxios
      .post('/statistics/order/overview', data)
      .then(({data}) => {
        this.clear()
        data.order_overview
        .forEach(
            item => {
              let num = parseFloat(item.number_of_orders),
                  total = parseFloat(item.total_order_amount),
                  avg = parseFloat(item.average_order_amount),
                  tag = item.order_type === 'c' ? 'companyOrders' : 'privateOrders'

              this[tag].numOfOrders += num
              this[tag].totalAmount += total
              this[tag].avgAmount += avg
            }
        )

        this.totalOrdersNum = this.companyOrders.numOfOrders + this.privateOrders.numOfOrders
        this.totalSum = this.companyOrders.totalAmount + this.privateOrders.totalAmount
        this.totalAvg = (this.companyOrders.avgAmount + this.privateOrders.avgAmount) / 2

        this.companyOrders.percentage = this.companyOrders.numOfOrders / (this.totalOrdersNum / 100)
        this.privateOrders.percentage = this.privateOrders.numOfOrders / (this.totalOrdersNum / 100)

        this.isLoading = false
      })
      .catch(err => {
        console.error(err)
        this.isLoading = true
      })
    },
    clear() {
      this.privateOrders = {
        numOfOrders: 0,
        totalAmount: 0,
        avgAmount: 0,
        percentage: 0
      }
      this.companyOrders = {
        numOfOrders: 0,
        totalAmount: 0,
        avgAmount: 0,
        percentage: 0
      }
      this.totalOrdersNum = null
      this.totalSum = null
      this.totalAvg = null
    }
  }
}
</script>

<style scoped>
tfoot td {
  /* color: #fff; */
  text-transform: uppercase;
  /* font-size: 0.8795rem; */
  font-weight: 600 !important;
  /* font-family: 'Titillium Web', Arial, 'Helvetica Neue', Helvetica, sans-serif !important; */
  padding: .75rem !important;
  text-align: left !important;
}

tfoot {
  background: #eeeeee;
}
</style>