<template>
    <div class="row">
        <div class="col-12">
            <h2>{{activeLanguage.progressPerHour}}</h2>
        </div>
        <div class="col-12">
            <div class="row">
                <loading-statistic :active="isLoading"
                                 :canCancel="true"></loading-statistic>
            </div>
            <LineChart
                v-if="showCharts"
                :chartData="chartDataHours"
                :chartOptions="chartOptionsHours"
            />
        </div>
    </div>
</template>

<script>
	import LineChart from '@/components/common/reusable/charts/LineChart'
    import Loading from './Loading'

	export default {
		name: "PerHourGraph",
        data() {
			return {
				chartDataHours: {},
				chartOptionsHours: {},
				showCharts: false,
                isLoading: false
            }
        },
		props: {
			activeLanguage: {
				type: Object,
				required: true
			}
		},
        methods: {
	        getPerHours({ filter, isEmployee }) {
		        let data = null
		        if (isEmployee) {
			        data = {
				        username: filter.client.username
			        }
		        }
		        data = {
			        ...data,
			        ...filter
		        }

		        delete data.client
		        delete data.isEmployee

                newbillinkAxios
			        .post('/statistics/order/per_hour_of_day', data)
			        .then(({data}) => {
				        let hoursLabels = [],
					        hoursPercent = []

				        Object.keys(data.orders_per_hour).forEach(
					        item => {
						        hoursPercent.push((parseFloat(data.orders_per_hour[item]) * 100).toFixed(1))
						        hoursLabels.push(item)
					        }
				        )

				        this.chartDataHours = {
					        labels: hoursLabels,
					        datasets: [
						        {
							        backgroundColor: '#F7BA7E',
							        borderColor: '#F48D20',
							        label: 'Orders',
							        data: hoursPercent
						        }
					        ]
				        }

				        this.chartOptionsHours = {
					        responsive: true,
					        maintainAspectRatio: false,
					        tooltips: {
						        callbacks: {
							        label: (tooltipItems) => tooltipItems.yLabel + '%'
						        }
					        },
					        scales: {
						        yAxes: [{
							        ticks: {
								        beginAtZero: true,
								        callback: (value) => `${value}%`
							        }
						        }]
					        },
							legend: {
								display: false
							}
				        }

				        this.showCharts = true
                        this.isLoading = false
			        })
			        .catch(err => {
			            console.error(err)
                        this.isLoading = true
			        })
	        },
        },
        components: {
	        LineChart,
            'loading-statistic': Loading
        }
	}
</script>

<style scoped>

</style>