<template>
    <div class="row">
        <div class="col-12">
            <h2>{{activeLanguage.progress}}</h2>
        </div>
        <div class="col-12">
            <div class="row">
                <loading-statistic :active="isLoading"
                                 :canCancel="true"></loading-statistic>
            </div>
            <LineChart
                  v-if="showCharts"
                  :chartData="chartDataMonths"
                  :chartOptions="chartOptionsMonths"
            />
        </div>
    </div>
</template>

<script>
	import LineChart from '@/components/common/reusable/charts/LineChart'
    import Loading from './Loading'

	export default {
		name: "PerMonthGraph",
		props: {
			activeLanguage: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				chartDataMonths: {},
				chartOptionsMonths: {},
				showCharts: false,
                isLoading: false
			}
		},
		methods: {
			getPerMonth({ filter, isEmployee }) {
				let data = null
				if (isEmployee) {
					data = {
						username: filter.client.username
					}
				}
				data = {
					...data,
					...filter
				}

				delete data.client
				delete data.isEmployee

                newbillinkAxios
					.post('/statistics/order/per_month', data)
					.then(({data}) => {
						let monthsLabels = [],
							monthsAmount = []

						Object.keys(data.orders_per_month).forEach(
							item => {
								monthsAmount.push(data.orders_per_month[item].orders)
								monthsLabels.push(data.orders_per_month[item].year_month)
							}
						)

						this.chartDataMonths = {
							labels: monthsLabels,
							datasets: [
								{
									backgroundColor: '#F7BA7E',
									borderColor: '#F48D20',
									label: 'Orders',
									data: monthsAmount
								}
							]
						}

						this.chartOptionsMonths = {
							responsive: true,
							maintainAspectRatio: false,
							legend: {
								display: false
							}
						}

						this.showCharts = true
                        this.isLoading = false
					})
					.catch(err => {
					    console.error(err)
                        this.isLoading = true
					})
			},
		},
		components: {
			LineChart,
            'loading-statistic': Loading
		}
	}
</script>

<style scoped>

</style>