<template>
    <div class="main">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="title">
                        <h1 style="display: inline-block;vertical-align: middle;">
                            {{ activeLanguage.title}}
                        </h1>
                    </div>
                </div>
                <div class="col-12" style="margin-bottom: 20px;">
                    <StatisticsFilter
                            :activeLanguage="activeLanguage.statisticsFilter"
                            :computedConnectedUsers="computedConnectedUsers"
                            :isSuperUser="isSuperUser"
                            :manageOrdersPermission="manageOrdersPermission"
                            @filterUpdated="filterUpdated"
                    />
                </div>
            </div>
            <div class="row" v-if="showCharts" id="statisticsCharts">
                <div class="col-12">
                    <OrderDetailsTable
                            :activeLanguage="activeLanguage.detailsTable"
                            ref="StatisticsDetailTable"
                    />
                    <hr>
                </div>
                <div class="col-12">
                    <OrderOverviewTable
                            :activeLanguage="activeLanguage.overviewTable"
                            ref="StatisticsOverviewTable"
                    />
                    <hr>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                            <OrderPerCitiesOverMap ref="StatisticsPerCity" />
                            <hr>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <PerMonthGraph 
                        :activeLanguage="activeLanguage"
                        ref="StatisticsPermMonth" />
                    <hr>
                </div>
                <div class="col-12">
                    <PerHourGraph
                        :activeLanguage="activeLanguage"
                        ref="StatisticsPerHour" />
                    <hr>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from  "vuex"
    import moment from 'moment'

    import StatisticsFilter from '../components/statisticsView/StatisticsFilter.vue'
    import OrderDetailsTable from '../components/statisticsView/OrderDetailsTable.vue'
    import OrderOverviewTable from '../components/statisticsView/OrderOverviewTable.vue'
    import PerMonthGraph from '../components/statisticsView/PerMonthGraph.vue'
    import PerHourGraph from '../components/statisticsView/PerHourGraph.vue'
    import OrderPerCitiesOverMap from '../components/statisticsView/OrderPerCitiesOverMap.vue'

    export default {
        name: "statistics",
        data() {
            return {
                filter: {},
                showCharts: false
            }
        },
        computed: {
            ...mapGetters({
                activeLanguage: 'getStatisticsLanguage',
                isSuperUser: 'isSuperUser',
                computedConnectedUsers: 'getComputedConnectedUsers',
                manageOrdersPermission: 'userManage_orders',
                userDetails: 'getBillinkUserDetails'
            })
        },
        watch: {
            activeLanguage: function () {
                this.getStatisticsData()
            }
        },
        mounted() {
            this.getStatisticsData()
        },
        methods: {
            getStatisticsData() {
                if (!this.manageOrdersPermission || !!Object.keys(this.filter).length) {
                    this.showCharts = true

                    if (!this.filter.dateFrom) {
                        let subtractedDate = moment().subtract(2, "months").format('YYYY-MM-DD')
                        this.filter.dateFrom = subtractedDate
                    }

                    let data = {
                        isEmployee: this.manageOrdersPermission,
                        filter: this.filter
                    }

                    setTimeout(() => {
                        this.$refs.StatisticsDetailTable.getSegments(data)
                        this.$refs.StatisticsOverviewTable.getOrderOverview(data)
                        this.$refs.StatisticsPerCity.getCities(data)
                        this.$refs.StatisticsPermMonth.getPerMonth(data)
                        this.$refs.StatisticsPerHour.getPerHours(data)
                    }, 0)
                } else {
                    this.showCharts = false
                }
            },
            filterUpdated(data) {
                this.filter = data
                this.getStatisticsData()
            },
        },
        components: {
            OrderDetailsTable,
            OrderOverviewTable,
            PerHourGraph,
            PerMonthGraph,
            OrderPerCitiesOverMap,
            StatisticsFilter
        }
    }
</script>

<style scoped>

</style>