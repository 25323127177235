<template>
        <loading :active="active"
                 :can-cancel="canCancel"
                 :is-full-page="isFullPage"></loading>
</template>

<script>
    import 'vue-loading-overlay/dist/vue-loading.min.css'
    import Loading from 'vue-loading-overlay'

    export default {
        name: "loading-statistic",
        components: {
            Loading
        },
        props: {
            active: {
                type: Boolean,
                required: true,
                default: () => false
            },
            canCancel: {
                type: Boolean
            },
            isFullPage: {
                type: Boolean,
                default: () => false
            }
        }
    }
</script>

<style scoped>

</style>