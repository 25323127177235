<template>
    <div class="row">
        <div class="col-12">
            <h2>Bereik NL</h2>
        </div>
        <div class="col-12">
            <GmapMap
                :center="center"
                :zoom="zoom"
                style="width: 100%; height: 400px"
            >
                <GmapCluster
                    :zoomOnClick="true"
                    :calculator="calcClusterNum"
                >
                    <GmapMarker
                        v-for="(marker, index) in markers"
                        :key="index"
                        :label="marker.orders + ''"
                        :position="marker.position"
                    />
                </GmapCluster>
            </GmapMap>
        </div>
    </div>
</template>

<script>
	import GmapCluster from 'vue2-google-maps/dist/components/cluster'
	import cities from '../../helpers/cities' // upload to back end

	export default {
		name: "OrderPerCitiesOverMap",
		data() {
			return {
				chartDataHours: {},
				chartOptionsHours: {},
				showCharts: false,
                zoom: 7,
                center: { lat: 52.3702, lng: 4.8952 },
                markers: []
			}
		},
        methods: {
	        getCities({ filter, isEmployee }) {
		        let data = null
		        if (isEmployee) {
			        data = {
				        username: filter.client.username
			        }
		        }
		        data = {
                    ...data,
			        ...filter
		        }

                delete data.client
                delete data.isEmployee

                newbillinkAxios
			        .post('/statistics/order/per_city', data)
			        .then(({data}) => {
				        this.markers = []

                        data.orders_per_city.forEach(
                            ({ city, orders }) => {
                            	let position = this.getLocation(city)

                                if (!!position && !!orders) {
	                                this.markers.push({ position, orders })
                                }
                            }
                        )
                    })
                    .catch(err => console.error(err))
            },
            getLocation(city) {
                let cityLocation = cities.find(
		            item => item.city === city.toLowerCase()
                )

                return !!cityLocation ? { lat: parseFloat(cityLocation.lat), lng: parseFloat(cityLocation.lng) } : null
            },
	        calcClusterNum(markers, numStyles) {
		        let index = 0,
                    text = 0,
                    title = markers.length,
                    dv = title

		            markers.forEach(marker => text += parseFloat(marker.label))

		        while (dv !== 0) {
			        dv = parseInt(dv / 10, 10)
			        index++
		        }

		        index = Math.min(index, numStyles)

		        return { text, index,  title }
	        }
        },
        components: {
	        GmapCluster
        }
	}
</script>

<style scoped>

</style>